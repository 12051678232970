import { gql } from "apollo-boost";

export const INTENT_DETAIL_FRAGMENT = gql`
  fragment IntentDetailFrament on FBIntent {
    alias
    intentName
    displayName
    imageUrl
    messages
    trainingPhrases
    richAnswers {
      type
      title
      content
      imageUrl
      youtubeId
      sources {
        title
        url
      }
      tip {
        title
        description
      }
    }
    displayOrder
    isFeatured
    isDisabled
    suggestedIntent {
      alias
      displayName
    }
    category {
      alias
      name
    }
  }
`;
