import * as React from "react";
import VideoAnswer from "../../ui-library/answers/video-answer";

const FEATURED_VIDEO = {
  title: "The Coronavirus Explained & What You Should Do",
  youtubeId: "BtN-goy9VOY",
  content: [
    "This virus is the “Severe acute respiratory syndrome-related coronavirus 2”, that causes the disease called COVID19, and that everyone simply calls Coronavirus.",
  ],
  sources: [
    {
      title: "youtube.com",
      url: "https://www.youtube.com/watch?v=BtN-goy9VOY",
    },
  ],
};

const FeaturedVideo: React.FC = () => {
  return (
    <VideoAnswer
      intentName="featured video"
      answerIndex={0}
      isFeatured={true}
      {...FEATURED_VIDEO}
    />
  );
};

export default FeaturedVideo;
