import { useQuery } from "@apollo/react-hooks";
import { AllCovidLiveIntentBaseLinks } from "../../../apollo-types/AllCovidLiveIntentBaseLinks";
import { AllBlockchainLiveIntentBaseLinks } from "../../../apollo-types/AllBlockchainLiveIntentBaseLinks";
import { COVID_ALL_INTENTS_BASE, BLOCKCHAIN_ALL_INTENTS_BASE } from "./query";

export const useQueryCovidLiveIntents = () => {
  const { data, loading } = useQuery<AllCovidLiveIntentBaseLinks>(
    COVID_ALL_INTENTS_BASE
  );

  const intents = data?.covidAllIntents;

  return { liveIntents: intents, loading };
};

export const useQueryBlockchainLiveIntents = () => {
  const { data, loading } = useQuery<AllBlockchainLiveIntentBaseLinks>(
    BLOCKCHAIN_ALL_INTENTS_BASE
  );

  const intents = data?.blockchainAllIntents;

  return { liveIntents: intents, loading };
};
