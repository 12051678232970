import { gql } from "apollo-boost";
import { INTENT_BASE_INFO_FRAGMENT } from "../../../graphql-fragments/live-queries/intent-base-info-schema";

export const COVID_ALL_INTENTS_BASE = gql`
  query AllCovidLiveIntentBaseLinks {
    covidAllIntents {
      ...IntentBaseInfoFrament
    }
  }
  ${INTENT_BASE_INFO_FRAGMENT}
`;

export const BLOCKCHAIN_ALL_INTENTS_BASE = gql`
  query AllBlockchainLiveIntentBaseLinks {
    blockchainAllIntents {
      ...IntentBaseInfoFrament
    }
  }
  ${INTENT_BASE_INFO_FRAGMENT}
`;
