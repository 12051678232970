import { gql } from "apollo-boost";
import { INTENT_DETAIL_FRAGMENT } from "../../../graphql-fragments/live-queries/intent-details-schema";

export const COVID_INTENT_BY_NAME = gql`
  query CovidIntentByName($intentName: String!) {
    covidIntentByName(intentName: $intentName) {
      ...IntentDetailFrament
    }
  }
  ${INTENT_DETAIL_FRAGMENT}
`;

export const BLOCKCHAIN_INTENT_BY_NAME = gql`
  query BlockchainIntentByName($intentName: String!) {
    blockchainIntentByName(intentName: $intentName) {
      ...IntentDetailFrament
    }
  }
  ${INTENT_DETAIL_FRAGMENT}
`;
