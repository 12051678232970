import * as React from "react";
import { grid } from "../../design-tokens/grid";
import QuestionLink from "../../ui-library/question-link/question-link";
import LoadingState from "../../ui-library/loading-state/loading-state";
import { EventCategory } from "../../../utils/analytics";
import { useCovidIntentListQuery } from "../../../hooks/static-queries/use-covid-intent-list-query";
import { useQueryCovidLiveIntents } from "../../../hooks/live-queries/use-query-live-intents/use-query-live-intents";
import { useCovidIntentCategoriesQuery } from "../../../hooks/static-queries/use-covid-intent-categories-query";
import { ActiveIntent } from "../../../types/internal";
import CovidIntent from "../../covid-19/covid-intent/covid-intent";
import { IntentBaseInfoFrament } from "../../../apollo-types/IntentBaseInfoFrament";
import { getCOVIDIntentUrl } from "../../../utils/question-url";
import styles from "./intent-preview.styles";
import CategoryIntentSection, {
  PreviewQuestionButton,
} from "./category-intent-section";

interface CovidIntentPreviewProps {
  activeIntent?: ActiveIntent;
}

const CovidIntentPreview: React.FC<CovidIntentPreviewProps> = ({
  activeIntent: activeIntentInput,
}) => {
  const [activeIntent, setActiveIntent] = React.useState<
    ActiveIntent | undefined
  >(activeIntentInput);

  const intents = useCovidIntentListQuery();
  const { liveIntents, loading } = useQueryCovidLiveIntents();
  const categories = useCovidIntentCategoriesQuery();

  if (loading) {
    return <LoadingState />;
  }

  if (!intents || !liveIntents) {
    return null;
  }

  const orderedIntent = liveIntents.sort((a, b) => {
    if (!a.displayOrder && !b.displayOrder) {
      return 0;
    }
    if (!a.displayOrder) {
      return 1;
    }
    if (!b.displayOrder) {
      return -1;
    }
    return a.displayOrder - b.displayOrder;
  });

  const hashedCategories =
    categories?.reduce(
      (categories: { [key: string]: IntentBaseInfoFrament[] }, c) => {
        categories[c.name] = [];
        return categories;
      },
      {}
    ) || {};

  const groupedIntents = orderedIntent.reduce(
    (categories: { [key: string]: PreviewQuestionButton[] }, intent) => {
      const categoryName = intent.category?.name || "No category";
      const isInActive =
        intents.findIndex((i) => i.alias === intent.alias) === -1;
      if (categories[categoryName]) {
        categories[categoryName].push({ ...intent, isInActive });
      } else {
        categories[categoryName] = [{ ...intent, isInActive }];
      }

      return categories;
    },
    hashedCategories
  );

  return (
    <div css={styles.root}>
      <div css={styles.leftColumn}>
        <div css={styles.leftHeading}>
          <div>
            <div css={{ marginBottom: grid(1) }}>
              Total intents with page: {intents.length}
            </div>
            <div>Total intents can ask: {orderedIntent.length}</div>
          </div>
          <div>
            <QuestionLink
              to={`/internal/covid-19/create-intent/`}
              state={{ activeIntent }}
              eventCategory={EventCategory.Internal}
            >
              Add intent
            </QuestionLink>
          </div>
        </div>
        {Object.keys(groupedIntents).map((categoryName) => (
          <CategoryIntentSection
            key={categoryName}
            activeIntent={activeIntent}
            categoryName={categoryName}
            intents={groupedIntents[categoryName]}
            onIntentClick={setActiveIntent}
          />
        ))}
      </div>

      {!!activeIntent && (
        <div css={styles.rightColumn}>
          <div css={styles.rightContent}>
            <QuestionLink
              to={getCOVIDIntentUrl(activeIntent.alias)}
              eventCategory={EventCategory.Internal}
            >
              {activeIntent.displayName}
            </QuestionLink>

            <QuestionLink
              to={`/internal/covid-19/edit-intent/`}
              state={{ activeIntent }}
              eventCategory={EventCategory.Internal}
            >
              Edit
            </QuestionLink>
          </div>
          <CovidIntent
            term={activeIntent.displayName}
            intentName={activeIntent.intentName}
            intentDetectionConfidence={1}
          />
        </div>
      )}
    </div>
  );
};

export default CovidIntentPreview;
