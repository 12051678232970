import { Interpolation } from "@emotion/core";
import { DarkTheme } from "../../design-tokens/media-queries";
import { grid } from "../../design-tokens/grid";
import { Colors } from "../../design-tokens/colors";

const root: Interpolation = {
  display: "flex",
  height: "100vh",
  overflow: "hidden",
};

const leftColumn: Interpolation = {
  position: "relative",
  width: grid(42),
  height: "100%",
  paddingTop: 0,
  borderRight: `2px solid ${Colors.BorderGrey}`,
  overflowY: "auto",
  [DarkTheme]: {
    borderRight: `1px solid ${Colors.DarkModeBorder}`,
  },
};

const rightColumn: Interpolation = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: grid(2),
  overflowY: "auto",
};

const leftHeading: Interpolation = {
  position: "sticky",
  top: 0,
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: Colors.LightGrey,
  padding: grid(2),
  zIndex: 10,
  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBlack,
  },
};

const rightContent: Interpolation = {
  display: "flex",
  marginBottom: grid(2),
  "> *": {
    marginLeft: grid(2),
  },
};

const styles = {
  root,
  leftColumn,
  rightColumn,
  leftHeading,
  rightContent,
};

export default styles;
