import * as React from "react";
import PageHeading from "../../ui-library/page-heading/page-heading";
import LoadingState from "../../ui-library/loading-state/loading-state";
import { addQuestionMarkToTerm } from "../../../utils/format-term";
import Container from "../../ui-library/container/container";
import CollectQAndA from "../../common-page-sections/collect-q-and-a/collect-q-and-a";
import { grid } from "../../design-tokens/grid";
import { useQueryCovidIntentByName } from "../../../hooks/live-queries/use-query-intent-by-name/use-covid-intent-by-name";
import SuggestedIntent from "../../common-page-sections/suggested-intent/suggested-intent";
import { Topic } from "../../../types/topics";
import ECVBanner from "../third-party-banner/ecv-banner";
import NoDetectedIntent from "./no-detected-intent/no-detected-intent";
import CovidAnswers from "./covid-answers/covid-answers";

interface CovidIntentProps {
  term: string;
  intentName: string;
  intentDetectionConfidence: number;
}

const CovidIntent: React.FC<CovidIntentProps> = ({
  term,
  intentName,
  intentDetectionConfidence,
}) => {
  const { intent, loading } = useQueryCovidIntentByName(intentName);

  if (loading) {
    return (
      <div css={{ marginTop: grid(4) }}>
        <LoadingState />
      </div>
    );
  }

  if (!intent || !intentDetectionConfidence) {
    return <NoDetectedIntent />;
  }

  return (
    <div>
      <PageHeading
        subHeading={
          intentDetectionConfidence <= 1
            ? `You asked: ${addQuestionMarkToTerm(term)}...`
            : undefined
        }
        detail={`${Math.round(intentDetectionConfidence * 100)}% confidence`}
      >
        {intent.displayName}
      </PageHeading>

      <Container fullWidthOnMobile={true}>
        <CovidAnswers {...intent} />
      </Container>

      <Container>
        <SuggestedIntent
          suggestedIntent={intent.suggestedIntent}
          topic={Topic.COVID19}
        />

        <ECVBanner />

        <CollectQAndA />
      </Container>
    </div>
  );
};

export default CovidIntent;
