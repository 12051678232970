import * as React from "react";
import { QuestionButton } from "../../ui-library/question-link/question-link";
import { EventCategory } from "../../../utils/analytics";
import ChevronIcon from "../../ui-library/icons/chevron.svg";
import Image from "../../ui-library/image/image";
import { ActiveIntent } from "../../../types/internal";
import styles from "./category-intent-section.styles";

export interface PreviewQuestionButton {
  alias: string;
  intentName: string;
  displayName: string;
  displayOrder: number | null;
  isInActive?: boolean;
}

interface CategoryIntentSectionProps {
  activeIntent?: ActiveIntent;
  categoryName: string;
  intents: PreviewQuestionButton[];
  onIntentClick: (q: PreviewQuestionButton) => void;
}

const CategoryIntentSection: React.FC<CategoryIntentSectionProps> = ({
  activeIntent,
  categoryName,
  intents,
  onIntentClick,
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  return (
    <div css={styles.root}>
      <div css={styles.heading} onClick={() => setOpen(!isOpen)}>
        {categoryName}
        <Image
          src={ChevronIcon}
          alt="Toggle"
          css={styles.chevoronIcon}
          style={{ transform: `rotate(${isOpen ? "90deg" : "-90deg"})` }}
        />
      </div>
      {isOpen && (
        <div css={styles.content}>
          {intents.map((q, index) => (
            <div key={q.alias} css={styles.questionLine}>
              <span css={styles.seqence}>{index + 1}</span>
              <QuestionButton
                isHighlight={
                  activeIntent && activeIntent.displayName === q.displayName
                }
                onClick={() => onIntentClick(q)}
                eventCategory={EventCategory.Internal}
              >
                {q.displayName}
                {!intents.find((i) => i.intentName === q.intentName) && (
                  <span css={[styles.badge, styles.inActive]}>!</span>
                )}
                {q.displayOrder && (
                  <span css={[styles.badge, styles.featured]}>
                    {q.displayOrder}
                  </span>
                )}
              </QuestionButton>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoryIntentSection;
