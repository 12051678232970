import { Interpolation } from "@emotion/core";
import { grid } from "../../design-tokens/grid";
import { Colors } from "../../design-tokens/colors";
import { DarkTheme } from "../../design-tokens/media-queries";
import { Fonts } from "../../design-tokens/fonts";

const root: Interpolation = {
  ":last-of-type": {
    borderBottom: `1px solid ${Colors.BorderGrey}`,

    [DarkTheme]: {
      borderBottom: `1px solid ${Colors.DarkModeBorder}`,
    },
  },
};

const heading: Interpolation = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: Fonts.Weight.SemiBold,
  padding: grid(2),
  borderTop: `1px solid ${Colors.BorderGrey}`,
  cursor: "pointer",

  [DarkTheme]: {
    borderTop: `1px solid ${Colors.DarkModeBorder}`,
  },
};

const chevoronIcon: Interpolation = {
  height: 14,
  marginRight: grid(1),
  transition: "all 0.2s ease",
};

const content: Interpolation = {
  paddingLeft: grid(2),
  paddingRight: grid(2),
  paddingBottom: grid(3),
};

const questionLine: Interpolation = {
  display: "flex",
  alignItems: "center",
  marginBottom: 10,

  "> div": {
    flex: 1,
  },
};

const seqence: Interpolation = {
  width: grid(1.5),
  textAlign: "right",
  marginRight: grid(1),
};

const badge: Interpolation = {
  textAlign: "center",
  fontSize: "12px",
  lineHeight: "20px",
  minWidth: 20,
  fontWeight: Fonts.Weight.Bold,
  borderRadius: grid(2),
  padding: `0 ${grid(0.5)}`,
  marginLeft: grid(1),
  whiteSpace: "nowrap",
};

const inActive: Interpolation = {
  color: Colors.White,
  backgroundColor: Colors.MidGrey,
};

const featured: Interpolation = {
  color: Colors.White,
  backgroundColor: Colors.Pink,
};

const styles = {
  root,
  content,
  heading,
  chevoronIcon,
  questionLine,
  seqence,
  badge,
  inActive,
  featured,
};

export default styles;
