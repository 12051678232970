import { gql } from "apollo-boost";

export const INTENT_BASE_INFO_FRAGMENT = gql`
  fragment IntentBaseInfoFrament on FBIntent {
    alias
    intentName
    displayName
    displayOrder
    category {
      name
    }
  }
`;
